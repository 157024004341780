import React from 'react';
import { Badge, Box, Button, Icon, Link, SpaceBetween, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import ResourceRevisionDiff from '../../resource-revisions/ResourceRevisionDiff';
import i18n from '../../../i18n';
import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import {
  ResourceRevisionStructure,
  ResourceRevisionList
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { someRiskStatus } from './risk-table';
import { generateLink, generateRiskStatusDisplay } from '../../../common/common-components';
import { RiskStatus } from '../../../common/RiskStatus';
import { getSeverityEnum, Severity } from '../risks/Severity';
import { RiskCardDefinitionData } from './risk-cards';

export interface RiskColumnDefinitionData {
  readonly severity: string;
  readonly riskId: string;
  readonly resourceId: string;
  readonly ruleId: string;
  readonly ruleName: string;
  readonly dedupeToken: string;
  readonly status: string;
  readonly createdOn: string;
  readonly description: string;
  readonly approvalContainer: string;
  readonly reviewer: string;
  readonly reviewedOn: string;
}

export interface ReviewColumnDefinitionData {
  readonly reviewId: string;
  readonly status: string;
  readonly origin: string;
  readonly dateCreated: string;
}

export function severityValueContainer(severity: string) {
  if (getSeverityEnum(severity) === Severity.CRITICAL) {
    return (
      <Popover
        dismissButton={false}
        position='top'
        size='small'
        content={<StatusIndicator type='stopped'>{i18n.t('Critical risks can not be acknowledged.')}</StatusIndicator>}
        triggerType='text'
      >
        <Box color='text-status-error'>
          {severity} <Icon name='status-info' />
        </Box>
      </Popover>
    );
  }
  return severity;
}

/**
 * Custom descending sorting for the Severity column.
 * @param left first item
 * @param right second item
 * @returns Negative value if right occurs before left, 0 if they are equal, positive otherwise.
 */
export const severityComparator = (left: RiskColumnDefinitionData, right: RiskColumnDefinitionData) => {
  return getSeverityEnum(left.severity) - getSeverityEnum(right.severity);
};

const onExpandButtonClick = (
  obj: RiskColumnDefinitionData | ResourceRevisionStructure,
  reviewId: string,
  splitPanelContext: SplitPanelContextProps,
  contentIdentifier: string
) => {
  return () => {
    if (splitPanelContext.getContentIdentifier() === contentIdentifier) {
      splitPanelContext.reset();
      return;
    }
    splitPanelContext.setHeader(i18n.t('Change Details for') + ` ${obj.resourceId}`);
    splitPanelContext.setContent(
      <ResourceRevisionDiff resourceId={obj.resourceId} reviewId={reviewId} />,
      contentIdentifier
    );
    splitPanelContext.setOpen(true);
  };
};

const expandButton = (
  obj: RiskColumnDefinitionData | ResourceRevisionStructure,
  reviewId: string,
  splitPanelContext: SplitPanelContextProps
) => {
  const contentIdentifier = `diff-${reviewId}-${obj.resourceId}`;

  return (
    <Button
      variant='inline-link'
      data-testid={'expandRevisionDiffButton'} // used for easier lookup during testing
      onClick={onExpandButtonClick(obj, reviewId, splitPanelContext, contentIdentifier)}
    >
      <Icon
        name={splitPanelContext.getContentIdentifier() === contentIdentifier ? 'treeview-collapse' : 'treeview-expand'}
        variant='link'
      />
    </Button>
  );
};

const resourceIdCell = (obj: RiskColumnDefinitionData | ResourceRevisionStructure, reviewId: string) => {
  return <Link href={`/reviews/${reviewId}/change/${obj.resourceId}`}>{obj.resourceId}</Link>;
};

export const getExpandButtonColumnDefinition = (reviewId: string, splitPanelContext: SplitPanelContextProps) => ({
  id: 'Expand',
  header: '',
  cell: (obj: RiskColumnDefinitionData | ResourceRevisionStructure) => expandButton(obj, reviewId, splitPanelContext),
  width: 55,
  minWidth: 55
});

export const getResourceIdColumnDefinition = (reviewId: string) => ({
  id: 'resource',
  header: i18n.t('Resource'),
  cell: (obj: RiskColumnDefinitionData | ResourceRevisionStructure) => {
    return resourceIdCell(obj, reviewId);
  },
  minWidth: 220,
  sortingField: 'resourceId'
});

export const getSeverityColumnDefinition = () => ({
  id: 'severity',
  header: i18n.t('Severity'),
  cell: (obj: RiskColumnDefinitionData) => severityValueContainer(obj.severity),
  width: 120,
  sortingField: 'severity',
  sortingComparator: severityComparator
});

export const getRuleColumnDefinition = () => ({
  id: 'rule',
  header: i18n.t('Rule'),
  cell: (obj: RiskColumnDefinitionData) => generateLink(`/rules/${obj.ruleId}`, obj.ruleName, false),
  sortingField: 'ruleName',
  minWidth: 200
});

export const getStatusColumnDefinition = () => ({
  id: 'status',
  header: i18n.t('Status'),
  cell: (obj: RiskColumnDefinitionData) => generateRiskStatusDisplay(obj.status),
  width: 180,
  sortingField: 'status'
});

export const getDescriptionColumnDefinition = () => ({
  id: 'description',
  header: i18n.t('Description'),
  cell: (obj: RiskColumnDefinitionData) => obj.description,
  minWidth: 200,
  sortingField: 'description'
});

export const getCreationDateColumnDefinition = () => ({
  id: 'createdOn',
  header: i18n.t('Date Created'),
  cell: (obj: RiskColumnDefinitionData) => new Date(obj.createdOn).toLocaleString(undefined, { timeZoneName: 'short' }),
  minWidth: 220,
  width: 220,
  sortingField: 'createdOn'
});

export const getReviewBadges = (
  risksItems: RiskColumnDefinitionData[],
  resourceRevisionItems: ResourceRevisionList
) => {
  const allAcknowledged = risksItems.length && !someRiskStatus(risksItems, RiskStatus.UNACKNOWLEDGED);
  const unacknowledgedCount = risksItems.filter((item) => item.status === RiskStatus.UNACKNOWLEDGED).length;
  return (
    <SpaceBetween size='xs' direction='horizontal'>
      <Badge>Change count : {resourceRevisionItems.length}</Badge>
      <Badge>Risk count : {risksItems.length}</Badge>
      {risksItems.length ? (
        allAcknowledged ? (
          <Badge color='green'>All risks acknowledged</Badge>
        ) : (
          <Badge color='red'>Unacknowledged : {unacknowledgedCount}</Badge>
        )
      ) : (
        <></>
      )}
    </SpaceBetween>
  );
};

export const isRiskAcknowledgementDisabled = (item: RiskColumnDefinitionData | RiskCardDefinitionData): boolean => {
  return item.severity === 'Critical';
};
